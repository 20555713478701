import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "tachyons/css/tachyons.min.css";
import "../../css/common.css";

import Seo from "../../components/SeoComponent";
import Header from "../../components/Header";
import TitlePage from "../../components/ui/TitlePage";
import SectionTextPage from "../../components/ui/SectionTextPage";
import SectionImage from "../../components/ui/SectionImage";
import ListText from "../../components/ui/ListText";
import SectionImagePlayer from "../../components/ui/SectionImagePlayer";
import SectionSubMenu from "../../components/ui/SectionSubMenu";
import Footer from "../../components/Footer";

import { EXPERTISES_SUBMENU } from "../../constants/constants";

const Manufacturing = () => {
  const { t } = useTranslation();

  return (
    <>
      <Seo
        title={t("page-expertise.manufacturing.metadata.name")}
        description={t("page-expertise.manufacturing.metadata.description")}
      />
      <Header />
      <TitlePage
        category={t("page-expertise.manufacturing.category")}
        title={t("page-expertise.manufacturing.title")}
      />
      <SectionImage
        imageHD="manufacturing.jpg"
        imageMD="manufacturing-md.jpg"
        imageSD="manufacturing-sd.jpg"
        alt="manufacturing"
      />

      <SectionTextPage>
        <p className="section-title">{t("page-expertise.manufacturing.s1-1")}</p>
        <p>{t("page-expertise.manufacturing.s1-p1")}</p>
      </SectionTextPage>

      <SectionTextPage mode="dark">
        <p className="section-title">{t("page-expertise.manufacturing.s2-1")}</p>
        <p>{t("page-expertise.manufacturing.s2-p1")}</p>
        <p>{t("page-expertise.manufacturing.s2-p2")}</p>
      </SectionTextPage>

      <SectionTextPage>
        <p className="section-title">{t("page-expertise.manufacturing.s3-1")}</p>
        <p className="section-subtitle">{t("page-expertise.manufacturing.s3-2")}</p>
        <ListText text={t("page-expertise.manufacturing.s3-l1")} />
        <p className="section-subtitle">{t("page-expertise.manufacturing.s3-3")}</p>
        <ListText text={t("page-expertise.manufacturing.s3-l2")} />
        <ListText text={t("page-expertise.manufacturing.s3-l3")} />
        <ListText text={t("page-expertise.manufacturing.s3-l4")} />
        <ListText text={t("page-expertise.manufacturing.s3-l5")} />
      </SectionTextPage>

      <SectionImagePlayer
        imageHD="manufacturing-2.jpg"
        imageMD="manufacturing-2-md.jpg"
        imageSD="manufacturing-2-sd.jpg"
        href="https://www.youtube.com/watch?v=aAZ7aR3jMD0"
        target="_blank"
      />

      <SectionSubMenu menus={EXPERTISES_SUBMENU} title={t("page-expertise.other-menu-title")} codeExclude="manufact" />

      <Footer />
    </>
  );
};

export default Manufacturing;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
